import { ApplicationInitStatus, APP_INITIALIZER, Component, HostListener, Inject, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Settings, AppSettings } from './components/shared/services/color-option.service';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MarketSelectorComponent } from './components/shared/model/market-selector/market-selector.component';
import { ConfigService } from './components/shared/services/config.service';
import { UserService } from './components/shared/services/user.service';
import { PersistentService } from './components/shared/services/persistent.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'retail';
  public settings: Settings;
  public url: any;
  constructor(
    @Inject(APP_INITIALIZER) public appInit: ApplicationInitStatus,
    public configService: ConfigService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public appSettings: AppSettings,
    public router: Router,
    private translate: TranslateService,
    public userService: UserService,
    public persistent: PersistentService
    ) {

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if(sessionStorage.getItem('language')){
      translate.setDefaultLang(sessionStorage.getItem('language'));
      translate.use(sessionStorage.getItem('language'));
    }else{
      translate.setDefaultLang('en');
      translate.use('en');
    }
    this.settings = this.appSettings.settings;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }
  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    localStorage.setItem('userService', JSON.stringify(this.userService.userServiceModal));
    localStorage.setItem('retailData', JSON.stringify(this.persistent.retailData));
  }

  ngOnInit() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      // this.showMarketSelector();
    });
    document.documentElement.style.setProperty('--theme-deafult', '#FFA500');
  }
  showMarketSelector() {
    if (!(sessionStorage.getItem('IsMarketCountrySelected') === 'true')) {
      const dialogRef = this.dialog.open(MarketSelectorComponent, {
        maxWidth: '400px',
        data: {},
        panelClass: 'marketselector-dialog-container',
        disableClose: true

      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        const result = dialogResult;
      });
    }
  }

}
