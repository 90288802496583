export function getBaseLocation() {
    let registerRoute = ['/home', '/login', '/signup', '/products/[a-zA-Z0-9]', '/product/[a-zA-Z0-9]', '/product-detail/[a-zA-Z0-9]', '/application','/join',
        '/pages/[A-Za-z]', '/complete', '/main', '/about', '/checkout', '/faq', '/orderhistory', '/manageautoship', '/autoorderhistory', '/complete', '/404', '/order-success', '/signup', '/orderinvoice', '/forgotpassword', 'shipping'];
    if (registerRoute.includes(location.pathname)) {
        return '/thinkenergy';
    }
    if (location.pathname.includes('pages/') && location.pathname.includes('checkout/')) {
        let routeMatch = registerRoute.some((item) => {
            return new RegExp(item).test(location.pathname);
        });
        if (routeMatch) {
            return '/thinkenergy';
        }
    }
    let paths: string[] = location.pathname.split('/').splice(1, 1);
    let basePath: string = (paths && paths[0]) || 'thinkenergy';
    return '/' + basePath;
}
