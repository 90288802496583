import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DefaultUrlSerializer, Router, UrlSerializer, UrlTree } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { AppRoutingModule } from './app-routing.module';
import { ShopModule } from './components/shop/shop.module';
import { SharedModule } from './components/shared/shared.module';
import { ColorOptionsComponent } from './components/color-options/color-options.component';
import { NotTranslatedService } from './components/shared/services/not-ranslated.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MyLoaderComponent } from './components/my-loader/my-loader.component';
import { LoaderService } from './components/shared/services/loader.service';
import { LoaderInterceptor } from './components/shared/services/interceptors/loader-interceptor.service';
import { ConfigService } from './components/shared/services/config.service';
import { environment } from 'src/environments/environment';
import { CommonSetting } from './modals/commonsetting.modal';
import { PagesModule } from './components/pages/pages.module';
import { FindEnrollerComponent } from './components/shared/model/findenroller/findenroller.component';
import { PersistentService } from './components/shared/services/persistent.service';
import { ApiInterceptor } from './components/shared/services/interceptors/http-interceptor.service';
import { CookieService } from 'ngx-cookie-service';
import { getSubdomain } from 'tldts';
import { AuthGuard } from './components/shared/services/auth.guard.service';
import { LoggedInAuthGuard } from './components/shared/services/loggedin.guard.service';
import { APP_BASE_HREF } from '@angular/common';
import { getBaseLocation } from './baseUrl';

const initializeConfig = (configService: ConfigService, http: HttpClient, dailog: MatDialog, translate) => {
  const promise2 = new Promise((resolve, reject) => {
    let subdomain = getBaseLocation();
    if (!subdomain) {
    } else {
      subdomain = subdomain.replace('/', '');
    }
    http.get(`${environment.apiUrl}api/Customers/GetWebsiteInformation_V1?webAlias=${subdomain}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      }),
      withCredentials: true
    }).toPromise()
      .then((result: any) => {
        if (!result.Data) {
          //webalias not found so setting default to thinkenergy/samspace making call below
          http.get(`${environment.apiUrl}api/Customers/GetWebsiteInformation_V1?webAlias=thinkenergy`, {
            headers: new HttpHeaders({
              'Content-Type': 'application/json; charset=utf-8'
            }),
            withCredentials: true
          }).toPromise().then((result: any) => {
            //update url
            subdomain = 'thinkenergy'
            let a = window.location.pathname.split('/')[1].replace(location.pathname.split('/')[1], subdomain)
            window.location.href = location.origin + '/' + a + '/' + window.location.pathname.split('/')[2]
            configService.setWebsiteInfo(result.Data);

            //localconfig file get call
            http.get<any>(`assets/data/localconfig.json`, {
              headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8'
              })
            }).subscribe((data) => {
              configService.localSettings = data;
              return resolve(result.Data);
            });
            //localconfig file get call ends
          })
          //default webalias call ends
          //else block for found webalias and empty webalias also
        } else {
          configService.setWebsiteInfo(result.Data);

          //localconfig file get call
          http.get<any>(`assets/data/localconfig.json`, {
            headers: new HttpHeaders({
              'Content-Type': 'application/json; charset=utf-8'
            })
          }).subscribe((data) => {
            configService.localSettings = data;
            return resolve(result.Data);
          });
          //localconfig file get call ends
        }
      }).finally(() => {
      });
  });

  const promise1 = new Promise((resolve, reject) => {
    http.get(`assets/data/clientsetting.json`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        }),
        withCredentials: true
      }).toPromise()
      .then((result: CommonSetting) => {
        configService.init(result);
        return resolve(result);
      }).finally(() => {
      });
  });


  return async () => {
    const res = await Promise.all([promise2, promise1]);
    return res;
  };

};

@Injectable()
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    if (params.interpolateParams) {
      return params.interpolateParams['Default'] || params.key;
    }
    return params.key;
  }

  constructor(private nts: NotTranslatedService) { }
}

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {

    return super.parse(url.toLowerCase());
  }
}
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ColorOptionsComponent,
    MyLoaderComponent,
  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    SharedModule,
    ShopModule,
    PagesModule,
    NgxSkeletonLoaderModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxImageZoomModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
        deps: [NotTranslatedService]
      }
    })
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseLocation
    },
    ConfigService,
    { provide: APP_INITIALIZER, useFactory: initializeConfig, deps: [ConfigService, HttpClient, MatDialog], multi: true },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    Title,
    PersistentService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function (router: Router, cookieService: CookieService) {
        return new ApiInterceptor(router, cookieService);
      },
      multi: true,
      deps: [Router, CookieService]
    },
    AuthGuard,
    LoggedInAuthGuard
  ],
  exports: [
    MatDialogModule
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}