import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RestApiService } from '../services/restapi.service';

@Component({
  selector: 'app-email-verify-dialog',
  templateUrl: './email-verify-dialog.component.html',
  styleUrls: ['./email-verify-dialog.component.scss']
})
export class EmailVerifyDialogComponent implements OnInit, OnDestroy {
  emailNotVerified: boolean = false;
  emailVerified: boolean = false;
  showExplain: boolean = true;
  closing_Allowed: boolean;
  public interval:any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmailVerifyDialogComponent>,
    public apiService: RestApiService) { }

  ngOnInit(): void {
    this.verifyemailbylink(this.data.email)
  }
  verifyemailbylink(e) {
    this.showExplain = true

    document.getElementsByClassName('loading-spinner')[0].classList.add('hidden')
    const request = {
      Email: e
    };

    this.apiService.sendemail(request).subscribe((a) => {
      document.getElementsByClassName('loading-spinner')[0].classList.remove('hidden')
    })
    clearInterval(this.interval)
    let type = 'email'
    this.responsecheck(e,type)
  }
  responsecheck(e,type): any {

    this.interval = setInterval(() => {
      document.getElementsByClassName('loading-spinner')[0].classList.add('hidden')
      this.apiService.CheckReplyRespnose(e,type).subscribe((response) => {
        //true
        if ((response.Data == true) && (response.Message == "Success")) {
          this.emailNotVerified = false
          this.emailVerified = true
          this.showExplain = false
          this.data.service.isNOTverified_email = true
          document.getElementsByClassName('loading-spinner')[0].classList.remove('hidden')
          this.dialogRef.close();
          clearInterval(this.interval)
          clearTimeout(emailtimeout)
        } else if ((response.Data == false) && (response.Message == "Success")) {
          //returning for false
          return
        }
      })
    }, 5000)

    let emailtimeout = setTimeout(() => {
      clearInterval(this.interval)
      this.emailNotVerified = true
      this.showExplain = false
      this.data.service.isNOTverified_email = false
      document.getElementsByClassName('loading-spinner')[0].classList.remove('hidden')
    }, 120000);
  }

  ngOnDestroy(): void {
    this.dialogRef.close({ data: this.data.service.isNOTverified_email });
  }
}
