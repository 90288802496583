import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-text-sms-verify-dialog',
  templateUrl: './text-sms-verify-dialog.component.html',
  styleUrls: ['./text-sms-verify-dialog.component.scss']
})
export class TextSmsVerifyDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TextSmsVerifyDialogComponent>) { }

  ngOnInit(): void {
    document.getElementsByClassName('loading-spinner')[0].classList.add('hidden')
    setTimeout(() => {
      this.dialogRef.close();
    }, 10000);
  }
}