import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../shared/services/product.service';
import { ConfigService } from '../../shared/services/config.service';
import { DSProduct } from 'src/app/modals/dsproduct.modal';
import { UserService } from '../../shared/services/user.service';
import { UserServiceModal } from 'src/app/modals/userservice.modal';
import { UtilityService } from '../../shared/services/utility.service';
import { CompanyService } from '../../shared/services/company.service';
import { ShoppingCartService } from '../../shared/services/shopping-cart.service';
import { SwiperConfigInterface, SwiperNavigationInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Route, Router } from '@angular/router';
import { Cart1Service } from '../../shared/services/cart1.service';
import { ItemsListService } from '../../shared/services/itemsList.service';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  env: any;
  products: Array<DSProduct>;
  healthBeautyProduct: Array<DSProduct>;
  shopPacksProduct: Array<DSProduct> = [];
  quarterlyPacksProduct: Array<DSProduct>;
  userService: UserServiceModal;
  itemType = '';
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  private navigation: SwiperNavigationInterface = {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
  public allItems: { Category: any, CategoryId: any }[] = [];
  contentLoaded = false;
  public banners = [];
  public slides = [];

  public slider = [{ subtitle: 'They move when you move - no surprises.', title: 'Women\'s Tops', image: 'https://cdn.shopify.com/s/files/1/0926/9298/files/NEWBLACKOUTLEGGINGS_NAVY_1_085ee5dc-7e45-4d27-8a72-d75f18d4bb01_1080x.jpg?v=1600985773' },
  { subtitle: 'Coordinated outfits to get you training.', title: 'Training', image: 'https://cdn.shopify.com/s/files/1/0926/9298/files/IMGN_191028_AF1_sRGB91023_JASMINE_02_038_F1_sRGB_1080x.jpg?v=1600972867' },
  { subtitle: 'The very best activewear.', title: 'Women\'s Tights', image: 'https://cdn.shopify.com/s/files/1/0926/9298/files/Block_02_1080x.jpg?v=1600977240' },
  ];
  constructor(
    public configService: ConfigService,
    public translate: TranslateService,
    private titleService: Title,
    private productService: ProductService,
    public user: UserService,
    public companyService: CompanyService,
    public shoppingCartService: ShoppingCartService,
    public router: Router,
    public cart1Service: Cart1Service,
    public itemsListService: ItemsListService
  ) {
    this.userService = user.userServiceModal;

    this.env = environment
  }
  ngOnInit() {
    this.translate.get('global_Company_Title').subscribe((text: string) => {
      this.titleService.setTitle(this.translate.instant('pagetitle_home') + ' | ' + text);
    });
    setTimeout(() => {
      this.contentLoaded = true;
    }, 3000);
    this.slides = this.configService.localSettings.Home.PrimaryBanner;
  }

  ngAfterViewInit() {
    this.config = {
      slidesPerView: 1.5,
      spaceBetween: 20,
      initialSlide: 4,
      keyboard: true,
      navigation: this.navigation,
      pagination: this.pagination,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        860: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 1
        }
      }
    }
  }

  mouseEnter(index) {
    document.getElementById('btn' + index).classList.add("faded-in")
    document.getElementById('btn' + index).classList.remove("faded-out")
    setTimeout(() => {
      document.getElementById('btn' + index).style.opacity = "1";
    }, 590);

  }
  mouseOut(index) {
    document.getElementById('btn' + index).classList.add("faded-out")
    setTimeout(() => {
      document.getElementById('btn' + index).style.opacity = "0";
    }, 590);
    document.getElementById('btn' + index).classList.remove("faded-in")
  }

  public selectedCategory;
  // getAllProductsCategories() {
  //   this.selectedCategory = { CategoryId: 0 };
  //   this.productService.getProductByCategory(this.selectedCategory).subscribe(products => {
  //     this.productService.orders = products.map((item) => {
  //       item.Price = item.Price || (item.Prices && item.Prices[0] && item.Prices[0].Price);
  //       return item;
  //     });

  //     const uniqueItems = _.uniqBy(products, x => x.CategoryId);
  //     let uniqueRequireProduct = [];
  //     if(this.configService.localSettings.Global.CategoriesToFetch?.length > 0) {
  //       uniqueItems.filter((x) => {
  //         if (this.configService.localSettings.Global.CategoriesToFetch.indexOf(x.Category) > -1) {
  //           uniqueRequireProduct.push(x);
  //         }
  //       })
  //     }
  //     else {
  //       uniqueRequireProduct = [...uniqueItems];
  //     }

  //     this.itemsListService.selectedCategories = {};
  //     if (this.selectedCategory.Category == 'all') {
  //       this.itemsListService.selectedCategories['all'] = true;
  //     }
  //     this.itemsListService.categoryList = _.map(uniqueRequireProduct, (object) => {
  //       return _.pick(object, ['CategoryId', 'Category']);
  //     });
  //     this.itemsListService.products = this.productService.orders;
  //     this.itemsListService.type = 'order';
  //     this.itemsListService.getItemsByCategory(this.selectedCategory.Category);
  //     this.products = this.itemsListService.productList.slice(0.8);
  //     this.allItems = this.itemsListService.categoryList;
  //   });
  // }

  goToShop(id) {
    this.router.navigate(['/product', id]);
  }

  public OrderQuantityCount: any;

  handleProduct(item) {
    if (item.ItemOptions.length > 0) {
      this.router.navigate(['/product', item.ItemID]);
    }else{
      this.OrderQuantityCount = item && item.Quantity ? item.Quantity : 1;
      if (this.cart1Service['orderQuantity'][item.ItemID] >= 1) {
        this.increaseQuantiy('order', item)
      } else {
        this.cart1Service['orderQuantity'][item.ItemID] = this.OrderQuantityCount;
        this.cart1Service.addToCart(item, true, item.ItemID, false, false, false, true);
  
      }
    }
  }
  increaseQuantiy(type, item) {
    this.cart1Service.increaseQuantiy(item, type == 'autoship', type == 'pack');
  }
}
